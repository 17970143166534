
import Vue from 'vue';
import Component from 'vue-class-component';
import VueApexCharts from 'vue-apexcharts';
import WarningMessage from '@/components/warning-message.vue';
import { Socket } from 'vue-socket.io-extended';
import { SOCKET_EVENTS, SURVEY_STATUS } from '@/enum/enums';
import moment from 'moment';
import SummaryIrLoi from '@/components/summary-ir-loi.vue';

interface Summary {
  [key: string]: number
}

interface Response {
  LOI: number;
  estimateIR: number;
  IR: number;
  estimateLOI: number;
  startDate: Date;
  endDate: Date;
  summary: { [k: string]: number };
  completeCount: number;
  denominator: number;
}

@Component({
  components: {
    SummaryIrLoi,
    WarningMessage,
    VueApexCharts,
  },
})
export default class SurveyProgress extends Vue {
  private IR = 0;
  private estimateIR = 0;
  private LOI = 0;
  private estimateLOI = 0;
  private summary!: Summary;
  private total = 0;
  private completeCount = 0;
  private denominator = 0;
  private complete = 0;
  private terminate = 0;
  private quotaFull = 0;
  private startDate: Date = new Date();
  private endDate: Date = new Date();
  private remain = 0;
  private remainStr = '';
  private interval: ReturnType<typeof setInterval> | null = null;


  created() {
    this.getProgress();
  }

  beforeDestroy(): void {
    if (this.interval) clearInterval(this.interval);
  }

  async getProgress() {
    this.loading = true;
    try {
      const { result, data, message } = await this.axios.get(`/project/progress/${ this.surveyId }`);
      if (!result) throw message;
      const { IR, LOI, summary, startDate, endDate, estimateIR, estimateLOI, completeCount, denominator } = data as Response;
      this.startDate = new Date(startDate);
      this.endDate = new Date(endDate);
      this.IR = IR;
      this.estimateIR = estimateIR;
      this.LOI = LOI;
      this.estimateLOI = estimateLOI;
      this.summary = summary;
      const { COMPLETE, TERMINATE, QUOTA_FULL } = summary;
      Object.values(summary).forEach(value => this.total += value);
      this.completeCount = completeCount;
      this.denominator = denominator;
      this.complete = COMPLETE;
      this.terminate = TERMINATE;
      this.quotaFull = QUOTA_FULL;
      this.interval = setInterval(() => {
        if (this.ended) {
          if (this.interval) clearInterval(this.interval);
          this.remain  = 0;
          this.remainStr = '';
        } else {
          this.remain = (this.endDate.getTime() - new Date().getTime()) / 1000;
          const t = moment(this.endDate);
          t.locale(this.$i18n.locale);
          this.remainStr = t.toNow();
        }
      }, 1000);
    } catch (e) {
      this.validationMessages(e);
    }
    this.loading = false;
  }

  @Socket(SOCKET_EVENTS.SURVEY_ROUTE_OUT)
  async routeOut(data: { surveyId: string, status: SURVEY_STATUS }) {
    const { surveyId, status } = data;
    if (surveyId === this.surveyId) {
      this.total += 1;
      switch (status) {
        case SURVEY_STATUS.COMPLETE:
          this.summary.COMPLETE += 1;
          this.complete += 1;
          break;
        case SURVEY_STATUS.TERMINATE:
          this.summary.TERMINATE += 1;
          this.terminate += 1;
          break;
        case SURVEY_STATUS.QUOTA_FULL:
          this.summary.QUOTA_FULL += 1;
          this.quotaFull += 1;
          break;
      }
    }
  }

  get completePercentage(): string {
    return this.completeCount ? (this.complete / this.completeCount * 100).toFixed(1) : '0';
  }

  get terminatePercentage(): string {
    return this.denominator ? (this.terminate / this.denominator * 100).toFixed(1) : '0';
  }

  get quotaFullPercentage(): string {
    return this.denominator ? (this.quotaFull / this.denominator * 100).toFixed(1) : '0';
  }

  get warning() {
    const ir = Math.abs(this.IR - this.estimateIR) >= 20;
    const loi = Math.abs(this.LOI - this.estimateLOI) >= 10;
    return (ir || loi);
  }

  async forceCreateCollection() {
    const { result, data, message } = await this.axios.post(`/sampling/create-collection/${ this.surveyId }`);
    console.log(data);
  }
  get ended() {
    return this.endDate.getTime() < new Date().getTime();
  }

}
