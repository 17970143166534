var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"survey-progress card border-left-primary shadow py-2 w-100"},[(_vm.loading)?_c('div',{staticClass:"card-body p-4"},[_c('div',[_c('h5',{staticClass:"text-primary"},[_vm._v(" "+_vm._s(_vm.$t('PROJECT.CREATED.HOME.PROGRESS.PROGRESS'))+" ")])]),_c('b-skeleton',{staticClass:"w-100 mb-3",attrs:{"type":"input"}}),_c('b-skeleton',{staticClass:"w-100 mb-3",attrs:{"type":"input"}}),_c('b-skeleton',{staticClass:"w-100 mb-3",attrs:{"type":"input"}})],1):_c('div',{staticClass:"card-body p-4"},[_c('div',{staticClass:"d-flex"},[_c('h5',{staticClass:"text-primary"},[_vm._v(" "+_vm._s(_vm.$t('PROJECT.CREATED.HOME.PROGRESS.PROGRESS'))+" ")]),_c('div',{staticClass:"ml-2"})]),_c('div',{staticClass:"d-flex justify-content-start"},[_c('div',[_c('h6',[_vm._v(" "+_vm._s(_vm.ended ? _vm.$t('PROJECT.CREATED.HOME.PROGRESS.ENDED') : _vm.$t('PROJECT.CREATED.HOME.PROGRESS.LEFT'))+" ")]),(!_vm.ended)?_c('div',{staticClass:"d-flex flex-column"},[_c('div',[_c('h6',[_vm._v(_vm._s(_vm.$t('PROJECT.CREATED.HOME.PROGRESS.END_DATE'))+" : "+_vm._s(_vm.endDate.toLocaleString()))])]),_c('div',[(_vm.remainStr)?_c('h6',[_vm._v("("+_vm._s(_vm.$t('PROJECT.CREATED.HOME.PROGRESS.REMAIN_TO_END'))+" "+_vm._s(_vm.remainStr)+")")]):_vm._e()]),_c('div',[_c('h6',[_vm._v(" "+_vm._s(_vm.$t('PROJECT.CREATED.HOME.PROGRESS.LOI'))+" : "+_vm._s(_vm.LOI)+" "+_vm._s(_vm.$t('PROJECT.CREATED.HOME.PROGRESS.MIN'))+" ")])])]):_vm._e()]),_c('div',{staticClass:"d-flex justify-content-around"},[_c('VueApexCharts',{attrs:{"width":"80%","centered":"","height":"200","type":"radialBar","series":[
                    _vm.completePercentage
                  ],"options":{
                    labels: ["Completes"]
                  }}}),_c('VueApexCharts',{attrs:{"width":"100%","height":"200","type":"radialBar","series":[
                    (_vm.IR).toFixed(1)
                  ],"options":{
                    labels: ["IR"]
                  }}})],1)]),_c('hr'),_c('div',{staticClass:"d-block d-lg-flex justify-content-center justify-content-lg-around"},[_c('div',{staticClass:"text-primary text-center"},[_c('h2',[_vm._v(" "+_vm._s(_vm.$t('PROJECT.CREATED.HOME.PROGRESS.COMPLETE'))+" ")]),_c('h2',[_vm._v(_vm._s(_vm.complete.digit())+" / "+_vm._s(_vm.completeCount.digit()))]),_c('h4',[_vm._v(_vm._s(_vm.completePercentage)+"%")])]),_c('div',{staticClass:"text-secondary text-center"},[_c('h2',[_vm._v(" "+_vm._s(_vm.$t('PROJECT.CREATED.HOME.PROGRESS.TERMINATE'))+" ")]),_c('h2',[_vm._v(_vm._s(_vm.terminate.digit())+" / "+_vm._s(_vm.denominator.digit()))]),_c('h4',[_vm._v(_vm._s(_vm.terminatePercentage)+"%")])]),_c('div',{staticClass:"text-danger text-center"},[_c('h2',[_vm._v(" "+_vm._s(_vm.$t('PROJECT.CREATED.HOME.PROGRESS.QUOTA_FULL'))+" ")]),_c('h2',[_vm._v(_vm._s(_vm.quotaFull.digit())+" / "+_vm._s(_vm.denominator.digit()))]),_c('h4',[_vm._v(_vm._s(_vm.quotaFullPercentage)+"%")])])]),_c('hr'),(_vm.warning)?_c('warning-message',{attrs:{"message":_vm.$t('PROJECT.CREATED.HOME.PROGRESS.WARNING_MESSAGE')}}):_vm._e(),_c('div',{staticClass:"d-block d-lg-flex text-center font-weight-bold justify-content-between"},[_c('summary-ir-loi',{attrs:{"loading":_vm.loading,"IR":_vm.estimateIR,"LOI":_vm.estimateLOI,"dataType":_vm.$t('PROJECT.CREATED.HOME.PROGRESS.ESTIMATE'),"user":_vm.getUserGroup}}),_c('summary-ir-loi',{attrs:{"loading":_vm.loading,"IR":_vm.IR,"LOI":_vm.LOI,"dataType":_vm.$t('PROJECT.CREATED.HOME.PROGRESS.ACTUAL'),"user":_vm.getUserGroup}})],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }