
import { Vue, Component, Prop } from 'vue-property-decorator'
import moment from "moment";

@Component
export default class HomeInfo extends Vue {
  @Prop() supLoading!: boolean;
  @Prop() status!: string;
  @Prop() startDate!: string;
  @Prop() endDate!: string;
  @Prop() projectNo!: number;
  @Prop() managers!: string[];
  @Prop() country!: string;

  dateFormat(date: string) {
    return moment(date).format('YYYY-MM-DD');
  }
}
