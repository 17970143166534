
import { Vue, Component } from 'vue-property-decorator';
import CreatedNav from '@/pages/project/created/created-nav.vue';
import SurveyProgress from '@/pages/project/created/home/progress.vue';
import RegistrationStep from '@/pages/project/components/registration-step.vue';
import HomeInfo from '@/pages/project/created/home/info.vue';
import HomeChannels from '@/pages/project/created/home/channels.vue';
import { mixins } from 'vue-class-component';
import { CreatedMixin } from '@/mixins/created-mixin';
import { USER_LEVEL } from '@/enum/enums';

@Component({
  components: {
    RegistrationStep,
    SurveyProgress,
    CreatedNav,
    HomeInfo,
    HomeChannels,
  },
})
export default class CreatedHome extends mixins(Vue, CreatedMixin) {
  title = '';
  status = '';
  startDate = '';
  endDate = '';
  projectNo = 0;
  managers: string[] = [];
  country: string[] = [];
  supplier = {};

  async created() {
    await this.load();
    await this.mixinsLoad();
  }

  async load() {
    this.loading = true;
    try {
      const { result, data, message } = await this.axios.get(`/project/${ this.surveyId }`);
      if (!result) throw message;

      const {
        status,
        startDate,
        endDate,
        surveyNo,
        manager,
        country,
        title,
        supplier,
      } = data;

      this.title = title;
      this.status = status;
      this.startDate = startDate;
      this.endDate = endDate;
      this.projectNo = surveyNo;
      this.supplier = supplier;
      this.managers = manager.join('<br/>');
      this.country = country.join('<br/>');
    } catch (e) {
      this.validationMessages(e);
    }
    this.loading = false;
  }
}
